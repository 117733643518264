.siteContainer {
	float: none;
	margin-left: auto;
	margin-right: auto;
	max-width: 1400px;
	position: relative;
	width: 100%;
}

.siteContent {
	margin-left: 0%;
	margin-right: 0%;
	width: 100%;
}

main {
	width: 75%;
	float: left;
}

@media (max-width: 768px) {
	.siteContent {
		margin: 0;
		padding: 0 0;
		width: 100%;
	}

	.siteContainer {
		width: 100%;
	}
}

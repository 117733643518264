.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 2px solid rgb(210, 214, 220);
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    color: gray;
    font-weight: bold;

    &--selected {
      background: #fff;
      /* border-color: #aaa; */
      color: #000;
      border-radius: 5px 5px 0 0;
      border-bottom: 2px #ea3d3d solid;
      color: #ea3d3d;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      &:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

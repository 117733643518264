ul li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.sidebarContainer {
  width: 25%;
  margin-right: 0%;
  float: left;
  font-family: 'Open Sans', sans-serif;
}

.headerContainer {
  position: relative;
  max-width: 100%;
  // margin-left: auto;
  // margin-right: auto;
  padding-bottom: 50px;
  padding-top: 50px;
  text-align: center;
  // width: 80%;

  a {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    padding-bottom: 0px;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    text-transform: uppercase;
    text-decoration: none;
    color: #ea3d3d;
    transition: background 0.2s ease, color 0.2s ease;

    &:hover {
      color: #222222;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.workContainer {
  float: none;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0px;
  padding-top: 0px;
  width: 76%;
}

.group {
  padding-bottom: 40px;
  padding-top: 0px;
  margin: 0;
  padding: 0;
}

.galleryTitle {
  padding-bottom: 20px;
  padding-top: 0px;
  text-align: center;

  a {
    color: #1c344d;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    text-transform: uppercase;
    transition: background 0.2s ease, color 0.2s ease;

    &:active {
      color: #1c344d;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.projectTitle {
  padding-bottom: 10px;
  padding-top: 0px;
  text-align: left;

  a {
    color: #999999;
    font-size: 20px;
    font-style: normal;
    font-weight: 200;
    line-height: 35px;
    text-transform: none;
    transition: background 0.2s ease, color 0.2s ease;

    &:hover {
      color: #6e8e96;
      font-style: normal;
      font-weight: 500;
      text-transform: none;
    }
  }
}

.active {
  color: #999999 !important;
  font-style: normal !important;
  font-weight: 700 !important;
  text-transform: none !important;
}

.hamburgerClickArea {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
  padding: 30px 5%;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 50%;
}

.hamburger {
  position: relative;
  width: 24px;

  i {
    display: block;
    height: 2px;
    margin-bottom: 4px;
  }
}

.social {
  ul {
    margin: 0;
    padding: 0;

    li {
      a:hover svg {
        fill: #222222;
        opacity: 1;
      }
    }
  }

  li:first-child {
    padding-left: 0 !important;
  }

  li {
    padding-top: 0px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 15px;
    display: inline-block;
  }
}

.icon {
  vertical-align: middle;
  transition: fill 0.2s ease;
  fill: #999999;
  height: 30px;
  opacity: 1;
  width: 30px;
}

svg:not(:root) {
  overflow: hidden;
}

.responsiveNav {
  font-family: 'Open Sans', sans-serif;
  opacity: 1;
  transition: visibility 0s ease 0s, opacity 0.2s ease 0s;
  visibility: visible;
  background-color: #fff;
  bottom: 0;
  display: block;
  height: '100%';
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 50px 5% 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  transition: visibility 0s ease 0.2s, opacity 0.2s ease 0s;
  z-index: 99999;

  nav {
    display: block;
  }
}

.closeResponsiveClickArea {
  padding: 40px 10%;
  cursor: pointer;
  padding: 50px 6%;
  position: fixed;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
}

.closeResponsiveButton {
  top: 30px;
  height: 20px;
  position: fixed;
  right: 5%;
  width: 20px;

  &::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #000000;
    content: '';
    display: block;
    height: 20px;
    left: 50%;
    position: absolute;
    width: 2px;
  }

  &::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #000000;
    content: '';
    display: block;
    height: 20px;
    left: 50%;
    position: absolute;
    width: 2px;
  }
}

.jsEditableTarget {
  display: block;
  padding-bottom: 80px;

  float: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 100%;

  padding: 0;
}

.gallery-title {
  padding: 0 0 30px;
  display: block;
  text-align: center;
}

.project-title {
  padding: 0 0 30px;
  display: block;
  text-align: center;
}

@media (max-width: 768px) {
  .hamburgerClickArea {
    display: block;
  }

  .hamburger i {
    background-color: #000000;
  }

  .sidebarContainer {
    float: none;
    margin: 0;
    width: auto;
  }

  nav {
    display: none;
    padding: 0;
    width: 100%;
  }

  .header {
    max-width: 100%;
    padding: 0;
    width: 100%;
  }

  .headerContainer {
    padding: 40px 5%;
  }

  .projectTitle {
    padding: 0 0 30px;
    display: block;
    text-align: center;
  }

  .galleryTitle {
    padding: 0 0 30px;
    a {
      font-size: 22px;
      line-height: 32px;
    }
  }
}

@media (max-width: 540px) {
  .header a {
    font-size: 22px;
    line-height: 22px;
  }

  .headerContainer {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

img {
	vertical-align: top;
	border: 0;
}

.projectCovers {
	padding-bottom: 0px;
	padding-top: 0px;
	display: flex;
	flex-wrap: wrap;
}

@media (max-width: 768px) {
	.projectCovers {
		padding-bottom: 0px;
		padding-top: 0px;
	}

	main {
		width: 100%;
	}
}

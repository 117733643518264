* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

html {
  margin: 0;
  overflow-y: scroll;
  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

body {
  margin: 0;
}

footer {
  font-family: ftnk;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 60px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 60px;
  text-align: center;
  text-transform: none;
  width: 100%;
}
